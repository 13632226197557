<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <!-- MAIN CONTAINER -->
    <b-row>
      <b-col md="12" lg="12" sm="12">
        <KTCodePreview v-bind:title="''">
          <!-- CONTENT -->
          <template v-slot:preview>
            <!-- FILTER CONTENT -->
            <b-row class="mb-5"> </b-row>
            <!-- LIST DATA -->
            <b-row>
              <b-col md="12" lg="12" sm="12">
                <b-table
                  :items="mainList.dataset"
                  :fields="getTableFields"
                  :busy="onLoadingList"
                  class="table-bordered table-hover"
                >
                  <template v-slot:table-busy>
                    <vcl-table :speed="5" :animate="true" :columns="10" />
                  </template>
                  <template v-slot:cell(actions)="row">
                    <div class="justify-content-center">
                      <b-dropdown size="sm" id="dropdown-left" right>
                        <template slot="button-content">
                          <i
                            style="font-size: 1rem; padding-right: 0px"
                            class="flaticon2-settings"
                          ></i>
                        </template>
                        <b-dropdown-item @click="runJob(row.item.code)">
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem"
                              class="far fa-calendar-plus text-success"
                            ></i>
                            &nbsp; &nbsp; Chạy
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="stopJob(row.item.code)">
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem"
                              class="far fa-calendar-minus text-warning"
                            ></i>
                            &nbsp; &nbsp; Tạm dừng
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="removeJob(row.item.code)">
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem"
                              class="far fa-calendar-times text-danger"
                            ></i>
                            &nbsp; &nbsp; Xoá
                          </span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <!-- PAGINATION -->
            <b-row>
              <b-col lg="3" md="3" sm="12">
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số job:
                  {{ mainList.total }}
                </p>
              </b-col>
              <b-col lg="9" md="9" sm="12">
                <b-pagination-nav
                  class="custom-pagination"
                  v-show="mainList.pages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="mainList.pages"
                  use-router
                  @change="loadFunctionByCode"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </template>
        </KTCodePreview>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import { getToastInstance } from '../../../utils/toastHelper';
import { mapGetters } from 'vuex';
import moment from 'moment';
import Swal from 'sweetalert2';
import _ from 'lodash';
import axios from 'axios';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import { cmdUrl } from '../../../utils/apiUrl';

const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%'
};

export default {
  // Data of view.
  data() {
    return {
      title: 'Cron Job',
      mainList: {
        dataset: [],
        total: 0,
        pages: 1
      },
      onLoadingList: false,
      perPage: 3,
      currentPage: 1,
      linkGen: pageNum => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      getTableFields: [
        {
          key: 'code',
          label: 'Code',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '5%'
          },
          tdClass: 'text-center'
        },
        {
          key: 'time_cron',
          label: 'Biểu thức',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '10%'
          },
          tdClass: 'text-center'
        },
        {
          key: 'time_run',
          label: 'Thời gian chạy',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%'
          }
        },
        {
          key: 'last_run',
          label: 'Lần cuối cập nhật',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%'
          },
          formatter: value => {
            return moment(value).format('DD-MM-YYYY HH:mm:ss');
          }
        },
        {
          key: 'time_type',
          label: 'Kiểu',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '5%'
          },
          tdClass: 'text-left'
        },
        {
          key: 'db',
          label: 'Database',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '7%'
          },
          tdClass: 'break-line text-center'
        },
        {
          key: 'name',
          label: 'Tên',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '10%'
          }
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '10%'
          },
          tdClass: 'text-center'
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '10%'
          },
          tdClass: 'text-right',
          formatter: value => {
            return moment(value).format('DD-MM-YYYY HH:mm:ss');
          }
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          thStyle: {
            ...thStyleCommon
          }
        }
      ]
    };
  },
  // Component defination.
  components: {
    KTCodePreview,
    Loader,
    VclTable
  },
  // Mounted view.
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Cron job' }]);
  },
  created() {
    let code = this.$router.history.current.params.code;
    if (code) {
      this.loadFunctionByCode(code);
    }
  },
  // Created view.
  methods: {
    ...getToastInstance(),
    loadFunctionByCode: async function(code) {
      let url = cmdUrl.ToolSync.functionGets;
      axios
        .get(url, { params: { code: code } })
        .then(rs => {
          this.mainList = rs.data.data;
        })
        .catch(err => {
          if (!err.response) {
            this.makeToastFaile('Kiểm tra lại kết nối internet');
          } else {
            this.makeToastFaile(err.message);
          }
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true
      });
    },
    formatDate: function(dateVal) {
      const date = moment(dateVal);
      return date.format('DD/MM/yyyy');
    },
    convertPrice: function(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    showDeleteAlert: function(item) {
      Swal.fire({
        title: 'Xóa cron jon!',
        text: 'Bạn có chắc muốn xóa?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then(result => {
        if (result.value) {
          this.deleteItem(item.id);
        }
      });
    },
    runJob(code) {
      let url = cmdUrl.ToolSync.cronStart;
      axios
        .get(url, {
          params: {
            code: code
          }
        })
        .then(rs => {
          console.log(rs);
          this.loadFunctionByCode();
        })
        .catch(err => {
          console.log(err);
          this.makeToastFaile(err.message);
        });
    },
    stopJob(code) {
      let url = cmdUrl.ToolSync.cronStop;
      axios
        .get(url, {
          params: {
            code: code
          }
        })
        .then(rs => {
          console.log(rs);
          this.loadFunctionByCode();
        })
        .catch(response => {
          this.makeToastFaile(response.$error);
        });
    },
    removeJob(code) {
      let url = cmdUrl.ToolSync.cronRemove;
      axios
        .get(url, {
          params: {
            code: code
          }
        })
        .then(rs => {
          console.log(rs);
          this.loadFunctionByCode();
        })
        .catch(err => {
          this.makeToastFaile(err.message);
        });
    }
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
    sumInAmount() {
      const outItems = _.filter(this.mainList.dataset, item => {
        return this.isShowAmount(item.type, 'in');
      });
      return _.reduce(
        outItems,
        (sum, item) => {
          return sum + parseFloat(item.amount);
        },
        0
      );
    },
    rows() {
      return this.mainList.dataset.length;
    }
  }
};
</script>

<style>
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}
.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}
tbody {
  font-size: 12px;
}
.break-line {
  white-space: break-spaces;
}
</style>
